.table-striped-rows th {
    background-color: white;
    line-height: 0.1rem;
    width: 1%;
    white-space: nowrap;
}

.table-striped-rows tr:nth-child(odd) td {
    background-color: #F9F9F9;
    line-height: 0.1rem;
    width: 1%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 30rem;
    min-width: 1rem;
}

.table-striped-rows tr:nth-child(even) td {
    background-color: white;
    line-height: 0.1rem;
    width: 1%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 30rem;
    min-width: 1rem;
}