body {
  margin: 0;
  font-family: sans-serif
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* background-color: #282c34;     */
}

code {
  font-family: sans-serif
    monospace;
}
