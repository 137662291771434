.dropdown-button {
    display: "flex";
    align-items: "center";
    justify-content: "center";
    float: "right";
    background-color:"white";
}

.dropdown-button button:before {
    background-color: white !important;
}

.dropdown-button button:hover {
    background-color: #003469 !important;
}

.dropdown-button button {
    background-color: #003469 !important;;
}

.glossary-link:link, .glossary-link:visited, .glossary-link:hover, .glossary-link:active {
    color: black;    
}